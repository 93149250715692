<template>
  <div id="home-index">
    <h1 class="home-title">统计模块</h1>
    <n-grid x-gap="12" :cols="3" class="home-cont1">
      <n-gi>
        <div class="light-green">
          <n-icon size="80" :depth="1">
            <BusinessOutline />
          </n-icon>
          <div class="home-text">
            <p>{{school_number}}</p>
            <p>学校数</p>
          </div>
        </div>
      </n-gi>
      <n-gi>
        <div class="green">
          <n-icon size="80" :depth="1">
            <Book />
          </n-icon>
          <div class="home-text">
            <p>{{course_number}}</p>
            <p>课程数</p>
          </div>
        </div>
      </n-gi>
      <n-gi>
        <div class="light-green">
          <n-icon size="80" :depth="1">
            <DocumentTextSharp />
          </n-icon>
          <div class="home-text">
            <p>{{material_number}}</p>
            <p>教材数</p>
          </div>
        </div>
      </n-gi>
    </n-grid>

    <div class="home-cont2">
      <h1 class="home-title">课包发布流程</h1>
      <div class="home-cont2-divs">
        <div class="home-cont2-div1 home-cont2-div">
          <img src="../../../../public/img/home/1_round_solid.png" alt="" />
          <span class="home-cont2-div-text1">添加资源</span
          ><span class="home-cont2-div-text2"></span>
          <div class="home-cont2-div1-1">在添加资源 / 习题 / 试卷资源</div>
        </div>
        <div class="home-cont2-div2 home-cont2-div">
          <img src="../../../../public/img/home/2_round_solid.png" alt="" />
          <span class="home-cont2-div-text1">创建教材</span
          ><span class="home-cont2-div-text2"></span>
          <div class="home-cont2-div1-1">创建教材 / 实训任务书</div>
        </div>
        <div class="home-cont2-div3 home-cont2-div">
          <img src="../../../../public/img/home/3_round_solid.png" alt="" />
          <span class="home-cont2-div-text1">创建课程</span
          ><span class="home-cont2-div-text2"></span>
          <div class="home-cont2-div1-1">
            选择创建好的标准教材打包成学科课程
          </div>
        </div>
        <div class="home-cont2-div4 home-cont2-div">
          <img src="../../../../public/img/home/4_round_solid.png" alt="" />
          <span class="home-cont2-div-text1">发布课程</span>
          <div class="home-cont2-div1-1">发布成功后即可给学生上课</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { DocumentTextSharp, Book, BusinessOutline } from "@vicons/ionicons5";
import { getHomeIndex } from "@/api/admin-home.js";
import { resStatusEnum } from "@/enumerators/http.js";
import { useMessage } from "naive-ui";

const { SUCCESS } = resStatusEnum;
const message = useMessage();
export default defineComponent({
  components: {
    DocumentTextSharp,
    Book,
    BusinessOutline,
  },
  data() {
    return {
      course_number:'',
      material_number:'',
      school_number:''
    };
  },
  // 方法
  methods: {
    initData(){
      var that = this
      getHomeIndex().then(res => {
        if (res.code === SUCCESS) {
          // console.log("首页Data:",res)
          that.course_number = res.data.course_number
          that.material_number = res.data.material_number
          that.school_number = res.data.school_number
        } else {
          useMessage.info(res.msg);
        }
      }).catch();
    }
  },
  // 挂载后4 (初始化)
  mounted: function() {
    this.initData()
    // console.log("4-mounted 挂载后 初始化");
  },
});
</script>

<style lang="less" scoped>
#home-index {
  // #744DFE;
}
.home-title {
  color: #000;
  font-size: 18px;
  padding: 20px;
  padding-bottom: 0;
  margin-bottom: 0;
  background: #fff;
}
.home-cont1 {
  padding: 20px;
  box-sizing: border-box;
  background: #fff;
  // max-height: 131px;
}
.home-cont1 .light-green,
.home-cont1 .green {
  padding: 20px;
  border: 1px solid #744DFE;
  min-height:90px
}
.home-cont1 .light-green:hover,
.home-cont1 .green:hover {
  border-color: #744DFE;
}
.n-icon.n-icon--depth.n-icon--color-transition {
  color: #744DFE !important;
  float: left;
}
.home-text {
  display: inline-block;
  width: 60%;
  margin-left: 9px;
  height: 100%;
}
.home-text p{
  font-size: 26px;
  line-height: 42px;
  text-align: center;
  margin: 0;
}
.green:hover .home-text,.light-green:hover .home-text{
  color: #744DFE;
}
#home-index .green:hover .n-icon.n-icon--depth.n-icon--color-transition,#home-index .light-green:hover .n-icon.n-icon--depth.n-icon--color-transition{
  color: #744DFE !important;
}
.home-cont2 {
  background: #fff;
  box-sizing: border-box;
  padding: 20px;
  margin-top: 20px;
  padding-bottom: 40px;
}
.home-cont2 .home-title {
  margin: 0;
  padding: 0;
}
.home-cont2-divs {
  padding-top: 90px;
  width: 954px;
  height: 120px;
  margin: 0 auto;
}
.home-cont2-divs .home-cont2-div {
  display: inline-block;
  height: 115px;
  float: left;
}
.home-cont2-divs .home-cont2-div img {
  height: 55px;
  width: 55px;
  float: left;
}
.home-cont2-div1,
.home-cont2-div2,
.home-cont2-div3 {
  width: 270px;
}
.home-cont2-div4 {
  width: 125px;
}
.home-cont2-div-text1 {
  display: inline-block;
  line-height: 50px;
  font-size: 16px;
}
.home-cont2-div-text2 {
  display: inline-block;
  width: 145px;
  height: 1px;
  background: #668bcf;
  position: relative;
  top: -5px;
}
.home-cont2-div1-1 {
  width: 130px;
  color: #999;
  text-align: left;
  text-indent: -50px;
}
.home-cont2-divs:after {
  content: "";
  height: 0;
  line-height: 0;
  display: block;
  visibility: hidden;
  clear: both;
}
</style>